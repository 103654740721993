<template>
  <div class="c2cbg">
    <div class="tab" style="justify-content: center;">
      <img src="../../assets/images/xg/zd.png" style="width: 8%;height: 8%;margin-top: 3%;margin-left: 2%;" @click="routerlink3" alt="" />
      <img src="../../assets/images/xg/hq.png" style="width: 8%;height: 8%;margin-top: 3%;margin-left: 2%;" alt="" />
      <div style="font-size: 20px;width: 50px;margin-left: 13%;margin-top: 4%;color: #1293D7;">兌換</div>
      <div style="font-size: 20px;width: 50px;margin-top: 4%;color: #fff;margin-left: 2%;">跨鏈</div>
      <div style="font-size: 20px;width: 50px;margin-top: 4%;color:#fff;margin-left: 2%;">行情</div>
      <img @click="showPopup" src="../../assets/images/xg/faq.png" style="width: 8%;height: 8%;margin-top: 3%;margin-left: 20%;" alt="" />
      <!-- <div class="btn" :class="{ active: itemIndex == 1 }" @click="btnClick(1)">我要买</div>
      <div class="btn" :class="{ active: itemIndex == 2 }" @click="btnClick(2)">我要卖</div> -->
      <!-- <van-nav-bar right-text="发起订单" @click-right="onClickRight"> </van-nav-bar> -->
      <!-- <div class="btn dis" style="color: #3E74EE;margin-left: auto;font-size: 18px;margin-right: 10px;"
        @click="onClickRight">发起订单</div> -->
    </div>
    <div class="detail">


      <div v-if="itemIndex == 1" >


        <div style="padding: 20px;">
           <div style="font-size: 15px;margin-left: 26%;">24H總成交量:{{user.qw_day_chengjiao_num}}</div>
          <img src="../../assets/images/usdt.png" style="width: 10%;height: 10%;margin-top: 10%;margin-left: 16%;" alt="" />
          <img :class="{ active: itemIndex == 1 }" @click="btnClick(2)" src="../../assets/images/dh.png" style="width: 8%;height: 8%;margin-left: 20%;margin-top: -10px;" alt="" />
          <img src="../../assets/images/xg/LOGO.svg" style="width: 10%;height: 10%;margin-left: 20%;" alt="" />
        </div>
        <div style="font-size: 15px;margin-left: 36%;">
          Roc實時價格
        </div>
        <div>
          <div style="margin-top: 5%;" >
            <input  type="number" v-model="num" style="border-radius: 10px;width: 20%;margin-left: 10%;background-color: #000000;border: #000000;height: 40px;font-size: 15px;" />
             <div>
              <div style="font-size: 15px;margin-left: 44%;margin-top: -8%;">
                {{price}}
              </div>
              <div style="font-size: 15px;margin-left: 73%;margin-top: -5%;">
               {{amount1.toFixed(2)}}
              </div>
            </div>
          </div>
          <div>
            <div @click="addOrder(num)" disabled  style="background-color: #1293D7;width: 40%;border-radius: 10px;height: 40px;margin-left: 30%;margin-top: 15%;font-size: 20px;text-align: center;padding-top: 5%;">
            立即兌換
            </div>
          </div>

        </div>
      </div>
      <div v-if="itemIndex == 2">
        <div style="padding: 20px;">
            <div style="font-size: 15px;margin-left: 26%;">24H總成交量:{{user.qw_day_chengjiao_num}}</div>
          <img src="../../assets/images/xg/LOGO.svg" style="width: 10%;height: 10%;margin-top: 10%;margin-left: 16%;" alt="" />
          <img :class="{ active: itemIndex == 2 }" @click="btnClick(1)" src="../../assets/images/dh.png" style="width: 8%;height: 8%;margin-left: 20%;margin-top: -10px;" alt="" />
          <img src="../../assets/images/usdt.png" style="width: 10%;height: 10%;margin-left: 20%;" alt="" />
        </div>
        <div style="font-size: 15px;margin-left: 36%;">
          Roc實時價格
        </div>
        <div>
          <div style="margin-top: 5%;">
            <input  type="number"  v-model="num" style="border-radius: 10px;width: 20%;margin-left: 10%;background-color: #000000;border: #000000;height: 40px;font-size: 15px;" />
            <div>
              <div style="font-size: 15px;margin-left: 44%;margin-top: -8%;">
               {{price}}
              </div>
              <div style="font-size: 15px;margin-left: 73%;margin-top: -5%;">
                {{amount2.toFixed(2)}}
              </div>
            </div>
          </div>
          <div>
            <div @click="sellOrder(num)" disabled style="background-color: #1293D7;width: 40%;border-radius: 10px;height: 40px;margin-left: 30%;margin-top:15%;font-size: 20px;text-align: center;padding-top: 5%;">
            立即兌換
            </div>
          </div>

        </div>
      </div>

    </div>

    <div style="background-color: rgb(30, 35, 41);border-radius: 10px;width: 90%;margin: auto;padding: 10px;margin-top: 10%;">
    <div style="color: #B3B4B7;font-size: 15px;width: 100%;display: flex;">
      <div style="width: 95%;">
        <img src="../../assets/images/xg/LOGO.svg" style="width: 30px;height: 30px;margin-left: 5%;" alt="" />
        <div style="color: aliceblue;margin-top: -8%;margin-left: 20%;">{{ nubsub }}
        <van-rate color="#1a90c0" v-model="user.level" readonly style="margin-left: 25%;" />
        </div>
      </div>
    </div>
    <div style="height: 230px;">
     <div style="color: #B3B4B7;font-size: 13px;width: 100%;margin-top:3%;">
       <img src="../../assets/images/xg/line.png" alt="" />
      <div style="display: flex;margin-top: 3%;">
       <div>
          <img src="../../assets/images/xg/LOGO.svg" style="width: 20px;height: 20px;margin-left: 65%;" alt="" />
       </div>
       <div style="margin-left: 18%;color: #fff;width: 15%;">
         ROC
       </div>
       <div style="margin-left: 32%;color: #fff;">
         {{balance.personal_money}}
       </div>
       </div>

      <div style="display: flex;margin-top: 3%;">
       <div>
          <img src="../../assets/images/usdt.png" style="width: 20px;height: 20px;margin-left: 65%;" alt="" />
       </div>
       <div style="margin-left: 18%;color: #fff;width: 15%;">
         USDT
       </div>
       <div style="margin-left: 32%;color: #fff;">
         {{balance.money}}
       </div>
       </div>

       <div style="display: flex;margin-top: 3%;">
        <div>
           <img src="../../assets/images/zl.png" style="width: 20px;height: 20px;margin-left: 65%;" alt="" />
        </div>
        <div style="margin-left: 18%;color: #fff;width: 15%;">
          總量
        </div>
        <div style="margin-left: 32%;color: #fff;">
           {{user.chengjiao_num}}
        </div>
        </div>
        <div style="display: flex;margin-top: 3%;">
         <div>
            <img src="../../assets/images/cj.png" style="width: 20px;height: 20px;margin-left: 65%;" alt="" />
         </div>
         <div style="margin-left: 18%;color: #fff;width: 15%;">
           已成交
         </div>
         <div style="margin-left: 32%;color: #fff;">
          {{user.chengjiao_num}}
         </div>
         </div>
         <div style="display: flex;margin-top: 3%;">
          <div>
             <img src="../../assets/images/je.png" style="width: 20px;height: 20px;margin-left: 65%;" alt="" />
          </div>
          <div style="margin-left: 18%;color: #fff;width: 15%;">
            總金額
          </div>
          <div style="margin-left: 32%;color: #fff;">
            {{user.total_price}}
          </div>
          </div>

       <!-- <div style="display: flex;margin-top: 10%;">
          <img src="../../assets/images/xg/LOGO.jpg" style="width: 20px;height: 20px;margin-left: 7.5%;" alt="" />
          <span style="margin-left: 10%;color: #fff;">USDT</span>
          <span style="margin-left: 82%;">{{balance.money}}</span>
       </div>
       <div style="display: flex;margin-top: 10%;">
          <img src="../../assets/images/xg/LOGO.jpg" style="width: 20px;height: 20px;margin-left: 7.5%;" alt="" />
          <span style="margin-left: 10%;color: #fff;">總量</span>
          <span style="margin-left: 82%;">{{user.chengjiao_num}}</span>
       </div>
       <div style="display: flex;margin-top: 10%;">
          <img src="../../assets/images/xg/LOGO.jpg" style="width: 20px;height: 20px;margin-left: 7.5%;" alt="" />
          <span style="margin-left: 10%;color: #fff;">已成交</span>
          <span style="margin-left: 82%;">{{user.chengjiao_num}}</span>
       </div>

       <div style="display: flex;margin-top: 10%;">
          <img src="../../assets/images/xg/LOGO.jpg" style="width: 20px;height: 20px;margin-left: 7.5%;" alt="" />
          <span style="margin-left: 10%;color: #fff;">總金額</span>
          <span style="margin-left: 82%;">{{user.total_price}}</span>
       </div> -->
      <!-- <span style="margin-left: 13%;position: absolute;margin-top: 2%;"><van-rate color="#1a90c0" v-model="user.level" readonly /></span> -->
       <!-- <div>
         總量: <span style="margin-left: 10%;">{{user.chengjiao_num}}</span>
       </div> -->
      <!-- <div>
         總金額: <span  style="margin-left: 2%;">{{user.total_price}}</span>
       </div>
       <div>
         已成交: <span  style="margin-left: 2%;">{{user.chengjiao_num}}</span>
       </div> -->
      <!-- <div>
         未成交: <span  style="margin-left: 2%;">{{user.not_chengjiao_num}}</span>
       </div> -->
     </div>
    </div>

    </div>

    <van-popup v-model:show="show" :style="{ height: '260px',padding: '64px',size:'5px',width:'60%' }" >
     <div style="position: absolute;margin-left: -18%;font-size: 15px;padding: 10px;">
       1.兌換貨幣：支持多主流幣種相互兌換。<br />
       2.手續費：兌換手續費為0.8%雙向收取。<br />
       3.兌換轉換：提供多種數字貨幣之間的兌換選擇，用戶可以根據需求選擇各種數字貨幣之間兌換轉換。<br />
       4.交易執行：鯤鵬DEX融合了dydx協議，運用混合兌換模式，具有兌換執行速度快、無滑點、低費用、無需信任等特點，用戶可以隨時兌換數字貨幣。
     </div>
        <van-button @click="closePopup" style="color: aliceblue;width: 100px;height: 60px;background-color: coral;border-radius: 10px;margin-left: 25%;margin-top: 230px;">已知悉</van-button>
    </van-popup>
  </div>

</template>

<script>
import { getTradeOrder, setTradeOrder,getRocPrice,getTradeOtherInfo } from "@/utils/api.js";
import { Popup, Button, radio, Locale } from 'vant';
import { getUserInfo } from "../../utils/api";
export default {
  data() {
    return {
      itemIndex: 1,
      list: [],
      selllist: [],
      show: false,
      type: '1',
      price: '',
      num: '',
      amount1:0,
      amount2:0,
      user:[],
      balance:[],
      nubsub:'',
    }
  },
  watch: {
      num: function(newValue) {
        this.amount1 = newValue / this.price || 0;
         this.amount2 = newValue * this.price || 0;
      },
    },
  created() {
    this.getlist()
    this.getrocjiage()
    this.getuser()
    this.getuserbalance()
  },
  methods: {
    qiehuan(id) {
      this.type = String(id)
    },
    routerlink3() {
      this.$router.push("/user/c2cDeltail");
    },
    addOrder(num) {
      let params = {
          roc_price : this.price ,
          num : this.num ,
          type : 1 ,
      }
      setTradeOrder(params).then((res) => {
        if (res.code == 200) {
          this.$toast("提交成功");
          location.reload();
        }
      })
    },
    closePopup (){
      this.show = false;
    },
    showPopup () {
          this.show = true;
        },
    sellOrder(num) {
      let params = {
          roc_price : this.price ,
          num : this.num ,
          type : 2 ,
      }
      setTradeOrder(params).then((res) => {
        if (res.code == 200) {
          this.$toast("提交成功");
        }
      })
    },
    btnClick(index) {
      this.itemIndex = index
    },
    onClickRight() {
      this.show = true;
    },
    clousePop() {
      this.show = false;
    },
    buy(item) {
      toBuy(item).then((res) => {
        if (res.code == 200) {
          this.$toast("提交成功");
        }
      })
      console.log('submit', item);
    },
    sell(item) {
      toSell(item).then((res) => {
        if (res.code == 200) {
          this.$toast("提交成功");
        }
      })
      console.log('submit', item);
    },
    getrocjiage(){
     getRocPrice().then((res) => {

       this.price = res.data.roc_price
     })
    },
    getuserbalance(){
     getUserInfo().then((res) => {

        this.balance = res.data.user
        this.nubsub = res.data.user.dapp_address.substring(0, 3) + "****" + res.data.user.dapp_address.substr(res.data.user
          .dapp_address.length - 4)
     })
    },
    getuser(){
     getTradeOtherInfo().then((res) => {

        this.user = res.data
     })
    },
    getlist() {
      let params = {
        page: 1,
        pagesize: 20
      }
      getTradeOrder(params).then((res) => {
        this.list = res.data.list
      })
    }
  }
};

</script>

<style lang="scss" >

.detail{
  // background-color: rgb(30, 35, 41);
  // background-color: rgb(30, 35, 41);
  background-image: url('../../assets/images/xg/c2c-bg.png');
  width: 85%;
  height: 620px;
  margin-top: 5%;
  margin-left: 8%;
  border-radius: 10px;
  color: #fff;

}
.c2cbg {
  background-color: #181a20;
  height: 100%;
  width: 100%;
  touch-action: pan-y;
  .tab {
    display: flex;

    .btn {

      margin-left: 5%;
      font-size: 50px;
    }
  }
}

.active {
  font-weight: bold;
  color: #3E74EE;
  font-size: 36px;
}
</style>

